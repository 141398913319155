<template>
  <component-frame title="Collapse">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li>
            <a href="#" v-scroll-to="'#accordion-simple'">Accordion - basic</a>
          </li>
          <li>
            <a href="#" v-scroll-to="'#accordion-complex'">Accordion - complex with nesting</a>
          </li>
          <li>
            <a href="#" v-scroll-to="'#navigation'">Collapse as responsive navigation</a>
          </li>
        </ul>
      </div>
    </div>

    <h3 id="accordion-simple">Accordion - basic</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div id="accordion">
            <button class="btn collapse-btn" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <svg class="icon icon-arrow_right collapse-icon">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
              </svg>
              Accordion Header #1
            </button>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="collapse-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin et ligula eget tortor faucibus laoreet sed a nunc. Nulla facilisi. Ut fringilla
                pellentesque urna, non facilisis nisi euismod eget.
              </div>
            </div>

            <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <svg class="icon icon-arrow_right collapse-icon">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
              </svg>
              Accordion Header #2
            </button>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
              <div class="collapse-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin et ligula eget tortor faucibus laoreet sed a nunc. Nulla facilisi. Ut fringilla
                pellentesque urna, non facilisis nisi euismod eget.
              </div>
            </div>

            <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <svg class="icon icon-arrow_right collapse-icon">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
              </svg>
              Accordion Header #3
            </button>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
              <div class="collapse-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin et ligula eget tortor faucibus laoreet sed a nunc. Nulla facilisi. Ut fringilla
                pellentesque urna, non facilisis nisi euismod eget.
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div id="accordion" class="border-top"&gt;
  &lt;button class="btn collapse-btn" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"&gt;
    &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
    Accordion Header #1
  &lt;/button&gt;
  &lt;div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion"&gt;
    &lt;div class="collapse-body"&gt;
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin et ligula eget tortor faucibus laoreet sed a nunc. Nulla facilisi. Ut fringilla pellentesque urna, non facilisis nisi euismod eget.
    &lt;/div&gt;
  &lt;/div&gt;

  &lt;button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"&gt;
    &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
    Accordion Header #2
  &lt;/button&gt;
  &lt;div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion"&gt;
    &lt;div class="collapse-body"&gt;
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin et ligula eget tortor faucibus laoreet sed a nunc. Nulla facilisi. Ut fringilla pellentesque urna, non facilisis nisi euismod eget.
    &lt;/div&gt;
  &lt;/div&gt;

  &lt;button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"&gt;
    &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
    Accordion Header #3
  &lt;/button&gt;
  &lt;div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion"&gt;
    &lt;div class="collapse-body"&gt;
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin et ligula eget tortor faucibus laoreet sed a nunc. Nulla facilisi. Ut fringilla pellentesque urna, non facilisis nisi euismod eget.
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Accordions shorten pages with a lot of content and is best used for creating a quick overview of the content when looking for a specific topic
          </li>
          <li>Useful for faq's, locations, and non-critical instructions</li>
          <li>
            If accordions contain complex content, consider using the complex accordion pattern below instead
          </li>
        </ul>
      </div>
    </div>

    <h3 id="accordion-complex">Accordion - complex with nesting</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="accordion mb-3 border-top">
            <!-- Collapse 1 -->
            <div class="border-bottom">
              <button
                class="btn collapse-btn py-2"
                data-toggle="collapse"
                data-target="#frequentCollapse"
                aria-expanded="true"
                aria-controls="frequentCollapse"
              >
                <svg class="icon icon-arrow_right collapse-icon">
                  <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
                </svg>
                <strong>Accordion Header 1</strong>
              </button>

              <div id="frequentCollapse" class="collapse show">
                <ul class="list-group list-group-indented mb-0">
                  <li class="list-group-item">
                    List item
                  </li>
                  <li class="list-group-item">
                    List item
                  </li>
                  <li class="list-group-item">
                    list item
                  </li>
                </ul>
              </div>
            </div>

            <!-- Collapse 2 -->
            <div class="border-bottom">
              <button
                class="btn collapse-btn collapsed py-2"
                data-toggle="collapse"
                data-target="#releasesCollapse"
                aria-expanded="false"
                aria-controls="releasesCollapse"
              >
                <svg class="icon icon-arrow_right collapse-icon">
                  <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
                </svg>
                <strong>Accordion Header 1</strong>
              </button>

              <div id="releasesCollapse" class="collapse" aria-labelledby="releasesCollapseHeading">
                <ul class="list-group list-group-indented mb-0">
                  <li class="list-group-item">
                    List item
                  </li>
                  <li class="list-group-item py-0">
                    <button
                      class="btn collapse-btn collapsed py-2"
                      data-toggle="collapse"
                      data-target="#nestedCollapse"
                      aria-expanded="false"
                      aria-controls="nestedCollapse"
                    >
                      <svg class="icon icon-arrow_right collapse-icon">
                        <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
                      </svg>
                      <strong>Accordion Header 2 - nested</strong>
                    </button>

                    <div id="nestedCollapse" class="collapse" aria-labelledby="nestedCollapseHeading">
                      <ul class="list-group list-group-indented mb-0">
                        <li class="list-group-item">
                          List item
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <!-- Collapse 3 -->
            <div class="border-bottom">
              <button
                class="btn collapse-btn py-2 collapsed"
                data-toggle="collapse"
                data-target="#group2Collapse"
                aria-expanded="false"
                aria-controls="group2Collapse"
              >
                <svg class="icon icon-arrow_right collapse-icon">
                  <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
                </svg>
                <strong>Accordion Header 1</strong>
              </button>

              <div id="group2Collapse" class="collapse" aria-labelledby="group2CollapseHeading">
                <ul class="list-group list-group-indented mb-0">
                  <li class="list-group-item">
                    List item
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="accordion mb-3 border-top"&gt;

  &lt;!-- Collapse 1 --&gt;
  &lt;div class="border-bottom"&gt;
    &lt;button class="btn collapse-btn py-2" data-toggle="collapse" data-target="#frequentCollapse" aria-expanded="true" aria-controls="frequentCollapse"&gt;
      &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
      &lt;strong&gt;Accordion Header 1&lt;/strong&gt;
    &lt;/button&gt;

    &lt;div id="frequentCollapse" class="collapse show"&gt;
      &lt;ul class="list-group list-group-indented mb-0"&gt;
        &lt;li class="list-group-item"&gt;
          List item
        &lt;/li&gt;
        &lt;li class="list-group-item"&gt;
          List item
        &lt;/li&gt;
        &lt;li class="list-group-item"&gt;
          list item
        &lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;

  &lt;!-- Collapse 2 --&gt;
  &lt;div class="border-bottom"&gt;
    &lt;button class="btn collapse-btn collapsed py-2" data-toggle="collapse" data-target="#releasesCollapse" aria-expanded="false" aria-controls="releasesCollapse"&gt;
      &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
      &lt;strong&gt;Accordion Header 1&lt;/strong&gt;
    &lt;/button&gt;

    &lt;div id="releasesCollapse" class="collapse" aria-labelledby="releasesCollapseHeading"&gt;
      &lt;ul class="list-group list-group-indented mb-0"&gt;
        &lt;li class="list-group-item"&gt;
          List item
        &lt;/li&gt;
        &lt;li class="list-group-item py-0"&gt;

          &lt;button class="btn collapse-btn collapsed py-2" data-toggle="collapse" data-target="#nestedCollapse" aria-expanded="false" aria-controls="nestedCollapse"&gt;
            &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
            &lt;strong&gt;Accordion Header 2 - nested&lt;/strong&gt;
          &lt;/button&gt;

          &lt;div id="nestedCollapse" class="collapse" aria-labelledby="nestedCollapseHeading"&gt;
            &lt;ul class="list-group list-group-indented mb-0"&gt;
              &lt;li class="list-group-item"&gt;
                List item
              &lt;/li&gt;
            &lt;/ul&gt;
          &lt;/div&gt;

        &lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;

  &lt;!-- Collapse 3 --&gt;
  &lt;div class="border-bottom"&gt;
    &lt;button class="btn collapse-btn py-2 collapsed" data-toggle="collapse" data-target="#group2Collapse" aria-expanded="false" aria-controls="group2Collapse"&gt;
      &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
      &lt;strong&gt;Accordion Header 1&lt;/strong&gt;
    &lt;/button&gt;

    &lt;div id="group2Collapse" class="collapse" aria-labelledby="group2CollapseHeading"&gt;
      &lt;ul class="list-group list-group-indented mb-0"&gt;
        &lt;li class="list-group-item"&gt;
          List item
        &lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Consider using this pattern when accordions contain lists, complex structures, or nested content
          </li>
        </ul>
      </div>
    </div>

    <h3 id="accordion">Collapse as responsive navigation</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <button
            class="btn btn-md btn-secondary d-md-none"
            type="button"
            data-toggle="collapse"
            data-target="#application-nav"
            aria-expanded="false"
            aria-controls="application-nav"
          >
            <svg class="icon icon-more">
              <use xlink:href="/icons/symbol-defs.svg#icon-more"></use>
            </svg>
            <svg class="icon icon-close">
              <use xlink:href="/icons/symbol-defs.svg#icon-close"></use>
            </svg>
            Navigate Application
          </button>
          <div class="collapse collapse-md" id="application-nav">
            <div class="step-wizard">
              <div class="step completed">
                <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
              </div>
              <div class="step active">
                <router-link class="step-link" to="/pages/edit-application/charges-liens-interests">Charge, Lien or Interest</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;button class="btn btn-md btn-secondary d-md-none" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav"&gt;
  &lt;svg class="icon icon-more"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-more"&gt;&lt;/use&gt;&lt;/svg&gt;
  &lt;svg class="icon icon-close"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-close"&gt;&lt;/use&gt;&lt;/svg&gt;
  Navigate Application
&lt;/button&gt;

&lt;div class="collapse collapse-md" id="application-nav"&gt;
  &lt;div class="step-wizard"&gt;
    &lt;div class="step completed"&gt;
      &lt;router-link class="step-link" to="/pages/edit-application/applicant"&gt;Applicant&lt;/router-link&gt;
    &lt;/div&gt;
    &lt;div class="step active"&gt;
      &lt;router-link class="step-link" to="/pages/edit-application/charges-liens-interests"&gt;Charge, Lien or Interest&lt;/router-link&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            add an extra class to the collapse to determine the breakpoint where the visible navigation should collapse
          </li>
          <li>
            Options are:<br />
            .collapse-sm<br />
            .collapse-md<br />
            .collapse-lg
          </li>
          <li>Resize screen to 768px to see the collapse</li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "tabs",
  data() {
    return {
      title: "Collapse",
    };
  },
};
</script>
